<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
                active
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2021
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>

          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Conoce el PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                ¿Cómo opera el PREP?
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            ¿Cómo opera el PREP?
          </h2>

          <b-embed
            type="video"
            aspect="16by9"
            controls
          >
            <source
              src="https://ayuda.ine.mx/2021/informate/assets/video/Como_opera_PREP.mp4"
              type="video/mp4"
            >
          </b-embed>
          <br />

          <p>
            El Instituto Nacional Electoral en el ámbito de sus atribuciones legales,
            es responsable de la implementación y operación del PREP,
            en función del tipo de elección que se trate.
          </p>

          <p class="text-center mb-5">
            <b>
              Referencias:
            </b>
            <br />
            · Artículo 219, párrafos 1 y 3 de la Ley General
            de Instituciones y Procedimientos Electorales. <br />
            · Reglamento de Elecciones, Artículos 340, 347 y 353.
          </p>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/c-operaPREP-1.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                Fases de operación del PREP
              </h4>
              <p>
                Al cierre de las votaciones, a las 18:00 horas (hora local),
                las y los funcionarios de casillas cuentan los votos y llenan las
                Actas de Escrutinio y Cómputo (AEC), en presencia de las
                representaciones de los Partidos Políticos y Candidaturas
                Independientes, posteriormente, se llevan a cabo las siguientes fases:
              </p>
            </div>
          </div>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                1. Toma de fotografía del Acta PREP en la casilla
              </h4>
              <p>
                Al concluir el llenado del AEC¹, es firmada por las y los funcionarios
                de Casilla, así como por las representaciones de los Partidos Políticos
                y Candidaturas Independientes, posteriormente la o el
                Capacitador Asistente Electoral:
              </p>
              <ul>
                <li>
                  Solicita el Acta PREP a la presidencia de la Mesa Directiva de Casilla.
                </li>
                <li>
                  Verifica que todos los datos de identificación del Acta PREP sean
                  legibles y pega el código QR con los datos correspondientes.
                </li>
                <li>
                  Toma la fotografía con la aplicación PREP Casilla y envía
                  la imagen al Centro de Recepción de Imágenes y Datos (CRID).
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/c-operaPREP-2.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
          </div>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/c-operaPREP-3.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                2. Acopio
              </h4>
              <p>
                En los Centros de Acopio y Transmisión de Datos (CATD):
              </p>
              <ul>
                <li>
                  Se recibe el Paquete Electoral en las mesas receptoras para la
                  extracción de la Bolsa-PREP, que contiene el Acta correspondiente.
                </li>
                <li>
                  Esta es entregada a la o el Acopiador, quien utiliza el Dispositivo de
                  Sellado Automático para imprimir la fecha y hora de acopio en el Acta PREP.
                </li>
              </ul>
            </div>
          </div>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                3. Digitalización
              </h4>
              <p>
                Se corrobora que las Actas PREP tengan código QR
                y que coincida con los datos de identificación de las mismas.
              </p>
              <ul>
                <li>
                  La o el digitalizador realiza la captura digital del Acta
                  PREP con el equipo multifuncional o escáner.
                </li>
                <li>
                  Tanto las Actas recibidas en PREP Casilla como en CATD <sup>2</sup>,
                  son procesadas a través del sistema Monitor de Captura
                  de Actas Digitalizadas (MCAD).
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/c-operaPREP-4.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
          </div>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/c-operaPREP-5.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                4. Captura y Verificación de datos
              </h4>
              <p>
                Dos capturistas diferentes reciben la misma Acta PREP
                y la capturan, el sistema verifica que coincidan los datos.
              </p>
              <ul>
                <li>
                  Si los datos registrados por las o los dos capturistas no
                  coinciden, el Acta PREP se remite a un tercer capturista para su envío al CRID3.
                </li>
                <li>
                  En caso de que no coincida, se enviará
                  al Centro de Verificación (CV) para su resolución definitiva.
                </li>
              </ul>
            </div>
          </div>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                5. Publicación de resultados
              </h4>
              <p>
                La publicación de los resultados electorales preliminares inicia
                a las 20:00 horas (Tiempo del Centro) del domingo 6 de junio del 2021.
              </p>
              <p>
                La divulgación de los datos, imágenes y bases de datos
                del PREP están a cargo del Instituto y de los difusores oficiales.
              </p>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/c-operaPREP-6.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
          </div>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/c-operaPREP-7.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                6. Cotejo de Actas
              </h4>
              <p>
                Se corrobora con el sistema Cotejo de Actas (CA) que los datos
                publicados coincidan con los datos asentados en el Acta PREP.
              </p>
            </div>
          </div>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                7. El cierre de la publicación
              </h4>
              <p>
                El PREP concluye, a más tardar, a las 20:00 horas
                (Tiempo del Centro) del lunes 7 de junio del 2021.
              </p>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/c-operaPREP-8.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
          </div>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/c-operaPREP-9.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                Empaquetado de Actas
              </h4>
              <p>
                Se archivan las Actas PREP para su posterior
                entrega a la presidencia del Consejo Distrital que corresponda.
              </p>
            </div>
          </div>

          <p>
            <sup>1</sup>AEC: Acta de Escrutinio y Cómputo
          </p>
          <p>
            <sup>2</sup>CATD Centro de Acopio y Transmisión de Datos
          </p>
          <p>
            <sup>3</sup>CRID: Centro de Recepción de Imágenes y Datos
          </p>

          <section class="text-center">
            <p>
              <b>
                Referencias:
              </b>
            </p>

            <p>
              · Ley General de Instituciones y Procedimientos Electorales, Artículo 219. <br />
              · Reglamento de Elecciones del Instituto Nacional Electoral, artículos 336 al 354.
              <br />
              · Anexo 13, Capítulo 5. <br />
              · Proceso Técnico Operativo del PREP para el Proceso Electoral Federal 2020–2021.
            </p>
          </section>

          <a
            href="https://ayuda.ine.mx/2021/informate/assets/docs/Prep/Conoce_el_PREP_Infografias/Como_opera_el_PREP.pdf"
            target="_blank"
            class="btn btn-primary mx-auto d-block"
          >
            Descarga Infografía
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid {
  display: grid;
  align-items: center;
  gap: 45px;
  grid-template-columns: 50px 1fr;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}
</style>
